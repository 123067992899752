import { createContext, useContext, useState, useEffect } from 'react';
import settingsAPI from '../services/settingsAPI';

const SettingsContext = createContext();

export function SettingsProvider({ children }) {
    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                setLoading(true);
                const response = await settingsAPI.getSettings();

                if (response?.data?.general) {
                    // console.log('General Settings:', response.data.general);
                    const logoSetting = response.data.general.find(s => s.key === 'site_logo');
                    // console.log('Logo Setting:', logoSetting);
                }
                setSettings(response);
            } catch (error) {
                console.error('Failed to fetch settings:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const getSettingValue = (key) => {
        if (!settings?.data) {
            console.log('No settings data available');
            return null;
        }

        // Handle social media as a special case
        if (key === 'social_media') {
            return settings.data.social_media || [];
        }

        // Look in general settings first
        if (settings.data.general) {
            const setting = settings.data.general.find(s => s.key === key);
            // console.log(`Found setting for ${key}:`, setting);

            if (setting) {
                // For file-type settings, ensure we're returning the full URL
                if (setting.input_type === 'file' && setting.value) {
                    // console.log(`Returning file URL for ${key}:`, setting.value);
                    return setting.value;
                }
                return setting.value;
            }
        }

        // If not found in general, try other sections
        for (const section in settings.data) {
            if (section === 'social_media') continue;

            const sectionData = settings.data[section];
            if (Array.isArray(sectionData)) {
                const setting = sectionData.find(s => s.key === key);
                if (setting) {
                    // console.log(`Found setting in ${section} for ${key}:`, setting);
                    return setting.value;
                }
            }
        }

        // console.log(`No setting found for key: ${key}`);
        return null;
    };

    return (
        <SettingsContext.Provider
            value={{
                settings,
                loading,
                error,
                getSettingValue
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
}

export function useSettings() {
    const context = useContext(SettingsContext);
    if (context === undefined) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }
    return context;
} 