import { createContext, useContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  // Initialize from localStorage or detect browser language, default to 'ar'
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) return savedLanguage;

    // Detect browser language
    const browserLang = navigator.language || navigator.userLanguage;
    // If browser language starts with 'ar', use Arabic, otherwise default to Arabic anyway
    return browserLang.startsWith('ar') ? 'ar' : 'ar';
  });

  // Persist language changes to localStorage
  useEffect(() => {
    localStorage.setItem('language', language);
    // Update document direction for RTL support
    document.dir = language === 'ar' ? 'rtl' : 'ltr';
    // Update html lang attribute
    document.documentElement.lang = language;
  }, [language]);

  const toggleLanguage = () => {
    setLanguage(prev => prev === 'en' ? 'ar' : 'en');
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
} 