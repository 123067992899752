import { AuthProvider } from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { SettingsProvider } from './contexts/SettingsContext';
import GoogleMapsProvider from './components/providers/GoogleMapsProvider';
import AppContent from './AppContent';

export default function App() {
  return (
    <AuthProvider>
      <LanguageProvider>
        <SettingsProvider>
          <GoogleMapsProvider>
            <AppContent />
          </GoogleMapsProvider>
        </SettingsProvider>
      </LanguageProvider>
    </AuthProvider>
  );
}