import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useLanguage } from './contexts/LanguageContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Toaster } from 'react-hot-toast';

// Lazy load components
const Home = lazy(() => import('./components/Home'));
const AvailableProperties = lazy(() => import('./components/properties/AvailableProperties'));
const PropertyDetails = lazy(() => import('./components/properties/PropertyDetails'));
const MapView = lazy(() => import('./components/map/MapView'));
const CustomerService = lazy(() => import('./components/CustomerService'));
const Login = lazy(() => import('./components/auth/Login'));
const Register = lazy(() => import('./components/auth/Register'));
const RenterLayout = lazy(() => import('./components/renter/RenterLayout'));
const Profile = lazy(() => import('./components/renter/Profile'));
const Tours = lazy(() => import('./components/renter/Tours'));
const SavedProperties = lazy(() => import('./components/renter/SavedProperties'));
const MyBookings = lazy(() => import('./components/renter/MyBookings'));
const OwnerLayout = lazy(() => import('./components/owner/OwnerLayout'));
const OwnerProfile = lazy(() => import('./components/owner/Profile'));
const MyProperties = lazy(() => import('./components/owner/MyProperties'));
const PropertyForm = lazy(() => import('./components/owner/PropertyForm'));
const TourRequests = lazy(() => import('./components/owner/TourRequests'));
const OwnerSettings = lazy(() => import('./components/owner/Settings'));

// Loading component
const LoadingSpinner = () => (
    <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#BE092B]"></div>
    </div>
);

export default function AppContent() {
    const { language } = useLanguage();

    return (
        <>
            <Toaster position="top-right" />
            <div className="min-h-screen flex flex-col">
                <Navbar />
                <main className="flex-1">
                    <Suspense fallback={<LoadingSpinner />}>
                        <Routes>
                            {/* Public Routes */}
                            <Route path="/" element={<Home language={language} />} />
                            <Route path="/properties/available" element={<AvailableProperties language={language} />} />
                            <Route path="/properties/:id" element={<PropertyDetails language={language} />} />
                            <Route path="/map" element={<MapView language={language} />} />
                            <Route path="/customer-service" element={<CustomerService language={language} />} />

                            {/* Auth Routes */}
                            <Route path="/login">
                                <Route path="renter" element={<Login language={language} userType="renter" />} />
                                <Route path="owner" element={<Login language={language} userType="owner" />} />
                            </Route>

                            {/* Register Routes */}
                            <Route path="/register">
                                <Route path="renter" element={<Register language={language} userType="renter" />} />
                                <Route path="owner" element={<Register language={language} userType="owner" />} />
                            </Route>

                            {/* Renter Routes */}
                            <Route path="/renter" element={<RenterLayout language={language} />}>
                                <Route path="profile" element={<Profile language={language} />} />
                                <Route path="tours" element={<Tours language={language} />} />
                                <Route path="bookings" element={<MyBookings language={language} />} />
                                <Route path="saved" element={<SavedProperties language={language} />} />
                            </Route>

                            {/* Owner Routes */}
                            <Route path="/owner" element={<OwnerLayout language={language} />}>
                                <Route path="profile" element={<OwnerProfile language={language} />} />
                                <Route path="properties" element={<MyProperties language={language} />} />
                                <Route path="properties/add" element={<PropertyForm language={language} />} />
                                <Route path="properties/edit/:id" element={<PropertyForm language={language} />} />
                                <Route path="requests" element={<TourRequests language={language} />} />
                                <Route path="settings" element={<OwnerSettings language={language} />} />
                            </Route>

                            {/* Fallback route */}
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                    </Suspense>
                </main>
                <Footer language={language} />
            </div>
        </>
    );
} 