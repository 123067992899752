import { useSettings } from '../contexts/SettingsContext';
import {
  FaWhatsapp,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaXTwitter
} from 'react-icons/fa6';

export default function Footer({ language }) {
  const currentYear = new Date().getFullYear();
  const { loading, getSettingValue } = useSettings();
  const logo = getSettingValue('site_logo');
  const socialMedia = getSettingValue('social_media') || [];

  const getSocialIcon = (key) => {
    const iconClasses = "w-5 h-5";

    switch (key.toLowerCase()) {
      case 'linkedin':
        return <FaLinkedinIn className={iconClasses} />;
      case 'twitter':
      case 'x':
        return <FaXTwitter className={iconClasses} />;
      case 'instagram':
        return <FaInstagram className={iconClasses} />;
      case 'facebook':
        return <FaFacebookF className={iconClasses} />;
      case 'whatsapp':
        return <FaWhatsapp className={iconClasses} />;
      default:
        return null;
    }
  };

  const getSocialLabel = (key) => {
    switch (key.toLowerCase()) {
      case 'linkedin':
        return language === 'ar' ? 'لينكد إن' : 'LinkedIn';
      case 'twitter':
      case 'x':
        return language === 'ar' ? 'تويتر' : 'Twitter';
      case 'instagram':
        return language === 'ar' ? 'انستغرام' : 'Instagram';
      case 'facebook':
        return language === 'ar' ? 'فيسبوك' : 'Facebook';
      case 'whatsapp':
        return language === 'ar' ? 'واتساب' : 'WhatsApp';
      default:
        return key;
    }
  };

  // Filter out duplicate social media entries
  const getUniqueSocialMedia = (socialMedia = []) => {
    const seen = new Set();
    return socialMedia.filter(social => {
      const key = social.key.toLowerCase();
      if (seen.has(key)) return false;
      seen.add(key);
      try {
        new URL(social.value);
        return true;
      } catch {
        return false;
      }
    });
  };

  return (
    <footer className="bg-white mt-auto py-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center md:items-end justify-between">
          {/* Logo - Left */}
          <div className="mb-6 md:mb-0">
            {loading ? (
              <div className="h-32 md:h-40 w-40 bg-gray-100 animate-pulse rounded"></div>
            ) : logo ? (
              <img
                src={logo}
                alt="Footer Logo"
                className="h-32 md:h-40 w-auto"
              />
            ) : null}
          </div>

          {/* Copyright - Center */}
          <div className="text-center mb-6 md:mb-0">
            <p className={`text-sm text-gray-600 ${language === 'ar' ? 'font-arabic' : ''}`}>
              {language === 'ar'
                ? `© ${currentYear} ستارك للوساطة العقارية جميع الحقوق محفوظة.`
                : `© ${currentYear} Stark Brokers All rights reserved.`}
            </p>
          </div>

          {/* Social Media Icons - Right */}
          <div className="flex items-center gap-4">
            {loading ? (
              <div className="flex gap-4">
                {[1, 2, 3, 4].map((i) => (
                  <div key={i} className="w-10 h-10 bg-gray-100 animate-pulse rounded-full"></div>
                ))}
              </div>
            ) : (
              getUniqueSocialMedia(socialMedia).map((social) => {
                const icon = getSocialIcon(social.key);
                if (!icon) return null;

                return (
                  <a
                    key={social.id}
                    href={social.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 text-gray-600 hover:bg-[#BE092B] hover:text-white transition-all duration-300"
                    aria-label={getSocialLabel(social.key)}
                    title={getSocialLabel(social.key)}
                  >
                    {icon}
                  </a>
                );
              })
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}
